import SignInDialogPage from '../../cypress/pages/authentication/SignInDialogPage.cy';
import index from '../../cypress/fixtures';
import LocalStorageUtils from './localStorage.cy';
import PageLayoutPage from '../../cypress/pages/page-layout/PageLayoutPage.cy';
import NavbarPage from '../../cypress/pages/navbars/navbarPage.cy';
import LandingPagePage from '../../cypress/pages/landing-page/LandingPagePage.cy';
import { util } from 'chai';

export default class Utils {
  static shortWait() {
    cy.wait(5000);
  }

  static medWait() {
    cy.wait(10000);
  }

  static longWait() {
    cy.wait(15000);
  }

  static isButtonDisabled(button) {
    button.should('be.disabled');
  }
  static formatDataTestIdSelector(id: string) {
    return `[data-testid="${id}"]`;
  }

  //temporary funct might use later, keep it for now
  static interceptGraphql(operationName: string) {
    cy.intercept('POST', '**/graphql/', (req) => {
      req.body.forEach((b) => {
        if (b.operationName == operationName) {
          req.alias = operationName;
        }
      });
    });
    cy.wait(`@${operationName}`, {
      requestTimeout: 50000,
      responseTimeout: 50000,
    });
  }

  static awaitGraphqlRequest(operationName: string, then: () => void) {
    cy.intercept('POST', '**/graphql/', (req) => {
      req.body.forEach((b) => {
        if (b.operationName == operationName) {
          req.alias = operationName;
        }
      });
    });
    cy.wait(`@${operationName}`, {
      requestTimeout: 50000,
      responseTimeout: 50000,
    }).then(then);
  }

  static shouldNotBeEmptyString(str: string | null) {
    return expect(str).to.not.be.empty;
  }

  static shouldBeEmptyString(str: string) {
    return expect(str).to.equal('');
  }

  static shouldBeNull(element) {
    return expect(element).to.null;
  }

  //separated the graphql for the login to remove the longWait, if it doesn't fail on CircleCI will remove the commented code
  static loginUsingSignInForum(channelFixture: string, accountFixture: string) {
    // const pageLayoutPage = new PageLayoutPage();
    const navbarPage = new NavbarPage();
    const signInDialogPage = new SignInDialogPage();
    cy.visit('/');
    Utils.channelSelector(channelFixture);
    Utils.awaitGraphqlRequest('HomePageSections', () => {
      navbarPage.clickAccountActionsButton();
    });
    cy.fixture(accountFixture).then(({ email, password }) => {
      signInDialogPage.typeEmail(email);
      signInDialogPage.typePassword(password);
    });
    cy.intercept('POST', '**/graphql/', (req) => {
      req.body.forEach((b) => {
        if (b.operationName == 'TokenAuth') {
          req.alias = 'TokenAuth';
        }
      });
    });
    // Utils.longWait();
    signInDialogPage.clickSubmitButton().then(() => {
      cy.wait(`@${'TokenAuth'}`, {
        requestTimeout: 50000,
        responseTimeout: 50000,
      });
      // Utils.awaitGraphqlRequest('TokenAuth', () => {
      // TODO: snackbar is hidden by the splash screen
      // pageLayoutPage.assertSnackbar('Welcome Back!');
      signInDialogPage.dialog.should('not.exist').then(() => {
        navbarPage.assertAccountActions('account');
        Utils.shouldNotBeEmptyString(
          LocalStorageUtils.getLocalStorageValueBy('token') ?? '',
        );
      });
      // });
    });
  }

  static loginUsingLocalStorage() {
    cy.fixture(index.loginStates.emptyCart).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStorageMyNameUser() {
    cy.fixture(index.loginStates.emptyOrders).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStoragCheckoutUser() {
    cy.fixture(index.loginStates.addressUser).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static channelSelector(fixture: string) {
    const landingPagePage = new LandingPagePage();
    cy.fixture(fixture).then(({ zipCode }) => {
      landingPagePage.channelZipcodeInput.type(zipCode, { force: true });
      landingPagePage.channelZipcodeButton.click({ force: true });
    });
  }
}
