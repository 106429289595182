import { ApolloClient } from '@apollo/client';
import { SaleorManager } from 'teddly-sdk';
import { SaleorAPI } from 'teddly-sdk/lib/api';
import { ApolloConfigInput } from 'teddly-sdk/lib/types';
import { SentryErrorLink } from '@config/sentry/sentryErrorLink';
import { SentryResponseLink } from '@config/sentry/sentryResponseLink';

export const teddlySdkConfig = {
  apiUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL || 'https://api.teddly.dev/graphql/',
  channel: 'default-channel',
};

//for sdk
export const apolloConfigForSdk: ApolloConfigInput = {
  updateLinks: true,
  links: [SentryErrorLink, SentryResponseLink],
};

//for front
const apolloConfig: ApolloConfigInput = {
  updateLinks: true,
  links: [SentryErrorLink, SentryResponseLink],
};

const manager = new SaleorManager(teddlySdkConfig, apolloConfig);

let teddlyApi: SaleorAPI = null;
let teddlyApolloClient: ApolloClient<any> = null;

export async function getTeddlySdkApi() {
  if (teddlyApi === null) {
    const { api, apolloClient } = await manager.connect();
    teddlyApi = api;
    teddlyApolloClient = apolloClient;
  }
  return { teddlyApi, teddlyApolloClient };
}
