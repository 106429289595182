import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import CartPanel from '@components/Organisms/CartPanel';
import Header from '@components/Organisms/Header';
import ProductDescription from '@components/Organisms/Product/ProductDescription';
import SideBar from '@components/Organisms/Sidebar';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import ForgotPasswordFormController from './controllers/ForgotPasswordForm';
import LoginFormController from './controllers/LoginForm';
import ResetPasswordFormController from './controllers/ResetPasswordForm';
import DeleteAccountFormController from './controllers/DeleteAccount';

import SignUpFormController from './controllers/SignUpForm';
import { useInitCheckout } from '@components/Pages/Checkout/context/checkout-flow';
import { Page, Snackbar, SplashScreen } from '@teddly/teddly-ui-components';
import useWindowDimensions from '@hooks/useWindowDimension';
import { ShoppingListsProvider } from '@context/ShoppingListContext';
import { ids } from '../../../../cypress/pages/authentication/SignInDialogPage.cy';
import { useRouter } from 'next/router';
import { useRoutesContext } from '@context/RoutesContext';
import { HomePageProvider, useHomePageContext } from '@context/HomePageContext';
import { useChannelContext } from '@context/ChannelContext';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useAuth } from 'teddly-sdk';
import { getServerAssetsImageUrl } from '@utils';
import { useApolloClient } from '@apollo/client';
import { useCartContext } from '@context/CartContext';
import styles from './style.module.scss';
import classNames from 'classnames';

interface PageLayoutProps {
  isAppLoading: boolean;
  setIsAppLoading: (value: boolean) => void;
  children?: React.ReactNode;
}

function PageLayoutContent({
  children,
  isAppLoading,
  setIsAppLoading,
}: PageLayoutProps) {
  const { isCurrentPage, pages, navigateTo } = useRoutesContext();

  const {
    isPageSidebarNavOpen,
    snackbars,
    openLoginDialog,
    showSplashScreen,
    openSidebarNav,
    setShowSplashScreen,
    isLoading,
    setLoading,
  } = usePageLayoutContext();
  const { authenticated, signOut, user } = useAuth();

  const {
    isSearchFieldVisible,
    data: homePageData,
    loading: homePageLoading,
  } = useHomePageContext();

  const { isLargeTablet } = useWindowDimensions();

  const [whatsappButtonStyleState, setWhatsappButtonStyleState] =
    React.useState(undefined);

  const getBottomNavbarHeight = () => {
    const element = document.getElementById('bottom-navbar-container');
    if (element) {
      return element.getBoundingClientRect().height;
    }
  };

  const client = useApolloClient();
  const { emptyCartState, emptyCart } = useCartContext();

  useEffect(() => {
    if (authenticated && typeof window !== 'undefined') {
      window.pendo.initialize({
        visitor: {
          id: user?.id,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        account: {
          id: user?.id,
          location: user?.currentChannelSettings?.channel?.id,
        },
      });
    }
  }, [authenticated]);

  const isWithVariantsInLocalStorage = (localCartLines) => {
    const results =
      !!localCartLines &&
      Object.keys(JSON.parse(localCartLines)?.variants)?.length > 0;
    return results;
  };

  const deleteTokenWhenThereIsNotSelectedChannel = async () => {
    const tokenFieldName = 'token';
    const channelFieldName = 'channel';
    const dataCheckoutFieldName = 'data_checkout';
    const cartLinesFieldName = 'loaded-cart-variants';
    const localChannel = localStorage.getItem(channelFieldName);
    const localToken = localStorage.getItem(tokenFieldName);
    const localCartLines = localStorage.getItem(cartLinesFieldName);
    const localDataCheckout = localStorage.getItem(dataCheckoutFieldName);
    const isLoggedInWithoutChannel = localToken && !localChannel;
    const isLogoutWithLocalCheckout =
      localChannel &&
      (localDataCheckout || isWithVariantsInLocalStorage(localCartLines)) &&
      !localToken;
    if (isLoggedInWithoutChannel || isLogoutWithLocalCheckout) {
      await signOut();
      client.reFetchObservableQueries();
      emptyCartState();
      emptyCart();
    }
  };

  React.useEffect(() => {
    deleteTokenWhenThereIsNotSelectedChannel();
  }, []);

  const pageBodyPointer = document?.getElementById('pageBodyContainer');
  const diff = 160;
  const getIsShowWhatsappButton = () => {
    return pageBodyPointer && pageBodyPointer.scrollTop - diff <= 0;
  };
  const [showWhatsappButton, setShowWhatsappButton] = useState(
    getIsShowWhatsappButton(),
  );

  React.useEffect(() => {
    setWhatsappButtonStyleState({
      marginBottom: isLargeTablet
        ? `${(getBottomNavbarHeight() || 64) - 18}px`
        : '32px',
      ...(isLargeTablet && { marginRight: '-18px' }),
    });
  }, [isLargeTablet]);

  const { selectedChannel, isChannelSelected } = useChannelContext();

  useEffect(() => {
    if (document.readyState && showSplashScreen && !isCurrentPage(pages.HOME)) {
      setIsAppLoading(showSplashScreen);
    }
  }, [typeof document !== 'undefined' ? document?.readyState : undefined]);

  useEffect(() => {
    setIsAppLoading(showSplashScreen);
    if (!showSplashScreen && isLoading) {
      setLoading(false);
    }
  }, [showSplashScreen]);

  const getWhatsappUrl = (text?: string) => {
    let whatsappUrl = `https://api.whatsapp.com/send/?phone=${
      selectedChannel?.botPhone || process.env.NEXT_PUBLIC_WHATSAPP_PHONE
    }`;
    if (text) {
      whatsappUrl = `${whatsappUrl}&text=${text}`;
    }
    return whatsappUrl;
  };

  useEffect(() => {
    const handleScroll = (e?) =>
      setShowWhatsappButton(getIsShowWhatsappButton());
    if (typeof pageBodyPointer !== 'undefined' && !!pageBodyPointer) {
      pageBodyPointer.addEventListener('scroll', handleScroll);
    }
    return () => {
      handleScroll();
      if (pageBodyPointer) {
        pageBodyPointer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [pageBodyPointer]);

  return (
    <div>
      {whatsappButtonStyleState !== undefined && (
        <FloatingWhatsApp
          phoneNumber={
            selectedChannel?.botPhone || process.env.NEXT_PUBLIC_WHATSAPP_PHONE
          }
          accountName="Teddly"
          avatar="/favicon.png"
          chatMessage={`Welcome to Teddly,${'\n'}How can we help you?`}
          chatboxHeight={280}
          buttonStyle={whatsappButtonStyleState}
          buttonClassName={classNames(
            showWhatsappButton && styles.show,
            !showWhatsappButton && styles.hidden,
          )}
          allowClickAway
          allowEsc={true}
          onSubmit={(e, value) =>
            navigateTo({
              route: getWhatsappUrl(value),
              options: { scroll: false },
            })
          }
          darkMode={
            window?.matchMedia &&
            window?.matchMedia('(prefers-color-scheme: dark)')?.matches
          }
          statusMessage="Your only app for all things shopping"
        />
      )}

      <HomePageProvider>
        <Page>
          <Page.Navbar
            isShown={
              isChannelSelected ||
              ((isPageSidebarNavOpen || !isLargeTablet) && isChannelSelected)
            }
            onHomeClick={() =>
              navigateTo({
                route: pages.HOME,
                options: { scroll: true },
              })
            }
            onMenuClick={openSidebarNav}
            onLoginClick={openLoginDialog}
            onAccountClick={() => navigateTo({ route: pages.MY_ACCOUNT })}
            isLoggedIn={authenticated}>
            <SideBar />
          </Page.Navbar>
          <Page.Header
            isSearchFieldVisible={isSearchFieldVisible}
            isMobileHomePage={false}>
            <Header />
          </Page.Header>
          {children}
          {/* {router.pathname !== '/my-account' ? <Footer /> : ''} */}
          {/* <Page.BodyFooter
            // navigationLists={navigationLists}
            // onHelpClick={onHelpClick}
            appStoreIconUrl={
              'https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg'
            }
            googlePlayIconUrl={getServerAssetsImageUrl('Microsoft_icon.svg')}
            facebookIconUrl={getServerAssetsImageUrl('Facebook_logo.svg')}
            whatsappIconUrl={getServerAssetsImageUrl('whatsapp_logo.svg')}
            instagramIconUrl={getServerAssetsImageUrl('instagram_logo.svg')}
            navigationLists={navigationList}
            // onAppStoreClick={onAppStoreClick}
            // onGooglePlayClick={onGooglePlayClick}
            // onFacebookClick={onFacebookClick}
            // onInstagramClick={onInstagramClick}
            // onWhatsappClick={onWhatsappClick}
          /> */}
          {/* <div css={tw`fixed bottom-10 tablet:right-10 `}>
          <Whatsapp />
        </div> */}
          {/* <CartCheckout close={toggleCart} isOpen={showCart} /> */}
        </Page>
        <CartPanel />
        <ShoppingListsProvider>
          <ProductDescription />
        </ShoppingListsProvider>

        <LoginFormController />
        <SignUpFormController />
        <ForgotPasswordFormController />
        <ResetPasswordFormController />
        <DeleteAccountFormController />
        {snackbars?.map((v, index) => (
          <Snackbar data-testid={ids.snackbar} key={index} {...v} />
        ))}
      </HomePageProvider>
    </div>
  );
}

export function PageLayout({
  isAppLoading,
  setIsAppLoading,
  children,
}: PageLayoutProps): JSX.Element {
  return (
    <PageLayoutContent
      isAppLoading={isAppLoading}
      setIsAppLoading={setIsAppLoading}>
      {children}
    </PageLayoutContent>
  );
}

export default PageLayout;
