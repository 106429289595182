import { useState, useRef, useEffect, useCallback } from 'react';
import tw from 'twin.macro';
import { useNetworkState } from '@hooks/useNetworkState';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import TicketIcon from './TicketIcon';
import DeleteOutline from '@mui/material/Icon';
import { InputBase, Button, Snackbar } from '@teddly/teddly-ui-components';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';

const couponIcon = <TicketIcon />;

const CouponInput = ({
  status,
  onApply,
  onCancel,
  isHaveCoupon,
}: {
  status: 'idle' | 'loading' | 'completed' | 'error';
  onApply: (couponText: string) => void;
  onCancel: () => void;
  isHaveCoupon: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState('');
  return (
    <div>
      <div>
        <InputBase
          data-testid={ids.addCouponInput}
          disabled={status === 'loading'}
          leadingIcon={couponIcon}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          ref={inputRef}
          value={value}
          placeholder="Paste here"
          trailingTitle={'Apply'}
          trailingTitleOnClick={() => {
            if (value) onApply(value || '');
          }}
          // trailingTitle={
          //   <Button
          //     data-testid={ids.addCouponApplyButton}
          //     title={'Apply'}
          //     loading={status === 'loading'}
          //     disabled={!value}
          //     type="button"
          //   />
          // }
        />
      </div>
    </div>
  );
};

export default function Coupon() {
  const { addPromoCode, removePromoCode, checkout, loaded } =
    useCheckoutFlowContext();
  const [isOpen, setIsOpen] = useState(true);
  const { state, setCompleted, setError, setIdle } = useNetworkState<string>();
  const { addSnackbar, closeSnackbar, setLoading } = usePageLayoutContext();
  const [isUpdete, setIsUpdete] = useState(false);
  const success = state.status === 'completed';

  const isHaveCoupon =
    checkout && checkout.promoCodeDiscount?.voucherCode ? true : false;

  useEffect(() => {
    if (isHaveCoupon) {
      setIsOpen(true);
      setCompleted(checkout.promoCodeDiscount.voucherCode);
    }
  }, [isHaveCoupon]);

  useEffect(() => {
    if ((isUpdete && success) || state.status === 'error')
      addSnackbar({
        id: success ? 'completed' : 'error',
        onClose: () => closeSnackbar(success ? 'completed' : 'error'),
        message: success ? `Coupon Applied: ${state?.result}` : state?.error,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      });
  }, [state.status, isUpdete]);

  const handleApplyCoupon = (coupon: string) => {
    setLoading(true);
    addPromoCode(coupon)
      .then(({ data, dataError, functionError }) => {
        if (dataError || functionError) {
          setError(dataError.error || functionError.error);
        } else {
          if (data?.promoCodeDiscount.voucherCode) {
            setCompleted(coupon);
            setIsUpdete(true);
          } else {
            setError(
              'There is no coupon... but there is a gift card with this number...',
            );
          }
        }
      })
      .catch((e) => {
        console.error('Something went wrong while adding coupon');
        console.error(e);
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteCoupon = async () => {
    if (state.result) {
      setLoading(true);
      removePromoCode(state.result);
      setCompleted('');
      addSnackbar({
        id: 'delCoupon',
        onClose: () => closeSnackbar('delCoupon'),
        message: `Coupon Deleted`,
        variant: SnackbarVariant.SUCCESS,
      });
    } else {
      setIsOpen(false);
      setIdle();
    }
    setLoading(false);
  };

  return (
    <>
      {loaded && (
        <div>
          {!isOpen && (
            <Button variant="text" onClick={() => setIsOpen(true)}>
              Add Promo Code
            </Button>
          )}
        </div>
      )}
      {isOpen && !state.result && (
        <div>
          <CouponInput
            status={state.status}
            onApply={handleApplyCoupon}
            onCancel={handleDeleteCoupon}
            isHaveCoupon={isHaveCoupon}
          />
        </div>
      )}
      {isOpen && state.result && (
        <div css={tw`mt-4 grid grid-cols-2`}>
          <p css={tw`font-poppins uppercase text-sm flex items-center`}>
            <span css={tw`text-gray-999`}>
              <TicketIcon />
            </span>
            <span css={tw`flex flex-1 ml-3.5 gap-3`}>
              <span css={tw`inline-block text-gray-aaa`}>Coupon Applied:</span>{' '}
              <span css={tw`text-aqua-marine font-semibold`}>
                {state.result}
              </span>
            </span>
            {isHaveCoupon && (
              <button
                data-testid={ids.addCouponDeleteButton}
                onClick={handleDeleteCoupon}
                type="button"
                css={tw`ml-2 text-coral uppercase`}>
                <DeleteOutline></DeleteOutline>
              </button>
            )}
          </p>
        </div>
      )}
    </>
  );
}
