import { createContext, ReactNode, useContext } from 'react';
import { SaleorCheckoutAPI } from 'teddly-sdk/lib/api';
import { useCheckout } from 'teddly-sdk';

const CheckoutFlowContext = createContext<Partial<SaleorCheckoutAPI>>(null);

export function CheckoutFlowProvider({ children }: { children: ReactNode }) {
  const checkout = useCheckout();

  return (
    <CheckoutFlowContext.Provider value={{ ...checkout }}>
      {children}
    </CheckoutFlowContext.Provider>
  );
}

export function useCheckoutFlowContext() {
  const context = useContext(CheckoutFlowContext);

  if (!context)
    throw new Error(
      'CheckoutFlowContext was used outside of the CheckoutFlowProvider',
    );

  return context;
}
