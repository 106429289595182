import { Timeslot } from '@app/checkout';
import moment, { Moment } from 'moment-timezone';
import { useState } from 'react';
import { useDeliveryContext } from '@context/DeliveryContext';
import {
  getNowTimeTitle,
  getTimeslotEndMoment,
  getTimeslotStartMoment,
  getTimezone,
  isSameDate,
  MINIMUM_ORDER,
} from './utils';
import {
  Card,
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
  UserInfoBlock,
  DeliveryTimeslot,
} from '@teddly/teddly-ui-components';
import styles from './Delivery.module.scss';
import FastDeliveryIcon from 'public/assets/90-minutes-primary.svg';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useFastDeliveryContext } from '@context/FastDeliveryContext';
import { useRoutesContext } from '@context/RoutesContext';
import useWindowDimensions from '@hooks/useWindowDimension';
import { CheckoutTypeEnum, DeliveryTypeEnum } from 'teddly-sdk';
import { ids } from '../../../../../../cypress/pages/checkout/CheckoutPage.cy';
import { ICheckoutModel } from 'teddly-sdk/lib/helpers';

const orderConfirmImg = '/assets/images/DeliveryGuyWithGrocery.svg';

type DeliveryPickerProps = {
  setIsChanging: (value: boolean) => void;
  onCloseHeaderDialog?: () => void;
};

const deliveryDaysPickerQuantity = 7;

export default function DeliveryPicker({ setIsChanging, onCloseHeaderDialog }: DeliveryPickerProps) {
  const { availableShippingMethods, setSelectedShippingMethod } = useCheckoutShippingAddressContext();
  const { checkout } = useCheckoutFlowContext();
  const { isFastDelivery } = useFastDeliveryContext();
  const { navigateTo, pages } = useRoutesContext();

  const { availableTimeSlot: availableTimeSlotData, onTimeslotSet, selectedTimeslot } = useDeliveryContext();
  const { isLargeTablet } = useWindowDimensions();

  let availableTimeSlot: Timeslot[] = [];
  let nowTimeSlots: Timeslot[] = [];

  for (let index = 0; index < availableShippingMethods?.length; index++) {
    const element = availableShippingMethods[index];
    if (!element.isFastDelivery && !(availableTimeSlot?.length > 0)) {
      availableTimeSlot = availableTimeSlotData?.get(element.id)?.availableTimeslot;
    }
    if (element.isFastDelivery && !(nowTimeSlots?.length > 0)) {
      nowTimeSlots = availableTimeSlotData?.get(element.id)?.availableTimeslot;
    }
  }

  const [selectedDate, setSelectedDate] = useState<Moment>();
  const today = moment.tz(getTimezone());

  const isTodayAvailable: boolean =
    availableTimeSlot?.find((timeslot) => isSameDate(getTimeslotStartMoment(timeslot), today)) != null;
  today.add(isTodayAvailable ? 0 : 1, 'days');

  const allDays: Moment[] = [];
  for (let index = 0; index < deliveryDaysPickerQuantity; index++) {
    const day: Moment = moment.tz(getTimezone());
    day.add(index, 'days');
    allDays.push(day);
  }
  const optionalDays: Moment[] = availableTimeSlot?.map((timeslot) => getTimeslotStartMoment(timeslot));
  const getOptionalTimeslots = (id?: Moment): Timeslot[] =>
    availableTimeSlot?.filter((timeslot) => isSameDate(getTimeslotStartMoment(timeslot), id ? id : selectedDate));
  const optionalTimeslots: Timeslot[] = getOptionalTimeslots()?.map((timeslot) => {
    return {
      ...timeslot,
      isSelected: selectedTimeslot?.date === timeslot?.date,
    };
  });
  const selectDefaultTimeslot = (id: Moment) => {
    const optionalTimeslots = getOptionalTimeslots(id);
    const firstOptionalTimeslot = optionalTimeslots?.length > 0 ? optionalTimeslots[0] : null;
    if (
      firstOptionalTimeslot &&
      (!selectedTimeslot ||
        new Date(firstOptionalTimeslot?.date)?.getDate() != new Date(selectedTimeslot?.date)?.getDate())
    ) {
      setSelectedShippingMethod(availableShippingMethods.find((v) => !v.isFastDelivery));
      setIsChanging(true);
      onTimeslotSet(firstOptionalTimeslot);
    }
  };

  const extractAllDays = (days) => {
    return days.map((day) => {
      const dt: Moment = moment(day, 'YYYY-MM-DD HH:mm:ss');
      const isAvailable: boolean = optionalDays?.find((date) => isSameDate(date, day)) != null;
      const isChosen: boolean = isSameDate(day, selectedDate);
      return {
        id: day,
        weekday: dt.format('ddd'),
        monthAndDay: `${dt.format('MMM')} ${dt.format('D')}`,
        selected: isChosen,
        disabled: !isAvailable,
      };
    });
  };
  const leftToOrder = MINIMUM_ORDER - checkout?.subtotalPrice?.net?.amount;

  const onFormFinish = () => {
    if (setIsChanging) {
      setIsChanging(false);
    }
  };

  const getNowButtonTitle = () => {
    if (leftToOrder > 0) return 'Add items';
    return `get it ${getNowTimeTitle(nowTimeSlots[0])}`;
  };

  const getNowTitle = () => {
    if (leftToOrder > 0) return `Add $${leftToOrder.toFixed(2)} of eligible items for fast delivery!`;
    if (nowTimeSlots?.length > 0) return `You are eligible for fast delivery!`;
    return `Your address or some of the items are not eligible for fast delivery!`;
  };

  const onClickTimeslot = (shippingMethod: ICheckoutModel['availableShippingMethods'][0], timeslot: Timeslot) => {
    setSelectedShippingMethod(shippingMethod);
    onFormFinish();
    return onTimeslotSet(timeslot);
  };
  const showFastDeliveryBanner =
    nowTimeSlots?.length > 0 || isFastDelivery || checkout?.type === CheckoutTypeEnum.FAST_DELIVERY;

  return (
    <UserInfoBlock className={styles.userInfoBlock}>
      {showFastDeliveryBanner && (
        <Card
          className={styles.nowCard}
          imageClassName={styles.nowCardImage}
          imageSrc={!isLargeTablet ? orderConfirmImg : null}
          showButton
          imagePosition="left"
          title={
            <div className={styles.cardDescription}>
              <div className={styles.cardDescriptionTitle}>
                <FastDeliveryIcon />
                <div>{getNowTitle()}</div>
              </div>
              {(nowTimeSlots?.length > 0 || leftToOrder > 0) && (
                <Button
                  size="small"
                  title={getNowButtonTitle()}
                  onClick={async () => {
                    if (leftToOrder <= 0 && nowTimeSlots?.length > 0)
                      onClickTimeslot(
                        availableShippingMethods.find((v) => v.isFastDelivery),
                        nowTimeSlots[0],
                      );
                    else {
                      onFormFinish();
                      await navigateTo({ route: pages.HOME });
                      if (onCloseHeaderDialog) onCloseHeaderDialog();
                    }
                  }}
                />
              )}
            </div>
          }
        />
      )}
      {availableTimeSlot && (
        <DeliveryTimeslot
          datesTitle={
            checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
              ? 'Select pickup day'
              : showFastDeliveryBanner
                ? 'Or select a different delivery date'
                : 'Select delivery day'
          }
          // isWithFastDelivery={showFastDeliveryBanner}
          dates={extractAllDays(allDays)}
          onDateClick={(date) => {
            setSelectedDate(date.id);
            // selectDefaultTimeslot(date?.id);
          }}
          timeslotsTitle={
            checkout?.deliveryType == DeliveryTypeEnum.PICK_UP ? 'Select pickup time' : 'Select delivery time'
          }
          timeslots={
            selectedDate &&
            optionalTimeslots.length > 0 &&
            optionalTimeslots?.map((timeslot, index) => {
              const startTime: Moment = getTimeslotStartMoment(timeslot);
              const endTime: Moment = getTimeslotEndMoment(timeslot);
              return {
                timeslot: `${startTime.format('ha')} - ${endTime.format('ha')}`,
                // isSelected: timeslot?.isSelected,
                button: (
                  <Button
                    data-testid={ids.selectDeliveryTimeOptionOne + index}
                    title="Select"
                    variant={ButtonVariantEnum.OUTLINE}
                    size={ButtonSizeEnum.SMALL}
                    onClick={() =>
                      onClickTimeslot(
                        availableShippingMethods.find((v) => !v.isFastDelivery),
                        timeslot,
                      )
                    }
                  />
                ),
              };
            })
          }
        />
      )}
    </UserInfoBlock>
  );
}
