import { useState } from 'react';

import { useAuth, AuthMethodEnum } from 'teddly-sdk';

import SignUpDialog from '@components/Organisms/SignUpDialog';

import {
  PageDialog,
  SnackbarVariant,
  usePageLayoutContext,
} from '../../../../context/PageLayoutContext';
import { useRoutesContext } from '@context/RoutesContext';
import { FunctionRunResponse } from 'teddly-sdk/lib/api/types';
import { DataErrorAuthTypes } from 'teddly-sdk/lib/api/Auth/types';
import { formatGraphQLError } from '@utils';

export default function SignUpFormController() {
  const { registerAccount, oauthSignIn } = useAuth();
  const { navigateTo } = useRoutesContext();
  const [fieldErrorMessages, setFieldErrorMessages] = useState<
    Record<string, string>
  >({});

  const [showResend, setShowResend] = useState(false);

  const setResponseData = (
    res: FunctionRunResponse<DataErrorAuthTypes, undefined>,
  ) => {
    if (res?.dataError) {
      if (
        Array.isArray(res.dataError?.error) &&
        res.dataError?.error?.length > 0 &&
        res.dataError?.error[0]?.code === 'REQUIRED' &&
        res.dataError?.error[0]?.field === 'channel'
      ) {
        const snackbarId = 'selectChannelMessage';
        addSnackbar({
          id: snackbarId,
          onClose: () => closeSnackbar(snackbarId),
          message:
            'Firstly, you must type in your zip code, then create account',
          variant: SnackbarVariant.WARNING,
        });
      } else {
        // setFieldErrorMessages(formatGraphQLError(res.dataError.error));
      }
    } else if (res?.functionError) {
      // setErrorMessage(formatGraphQLError(res.functionError.error));
    } else if (res && res.data) {
      closeDialog();
    }
  };

  const onLoginFailed = (dataError: any) => {
    addSnackbar({
      id: 'error',
      onClose: () => closeSnackbar('error'),
      message: 'Email or Password is incorrect...',
      variant: SnackbarVariant.ERROR,
    });
    console.error(dataError?.error);
    // throw error;
  };

  const onLoginSucceeded = (data?: any) => {
    addSnackbar({
      id: 'Success',
      onClose: () => closeSnackbar('Success'),
      message: 'Welcome Back!',
      variant: SnackbarVariant.SUCCESS,
    });
    closeDialog();
  };
  const {
    openLoginDialog,
    closeDialog,
    open_dialog,
    addSnackbar,
    closeSnackbar,
  } = usePageLayoutContext();
  const handleSignUp = async ({
    email,
    password,
    firstName,
    lastName,
    phone,
  }) => {
    // params.phone = Number(params.phone.replace(/[\(\)\s+-]/g, ''));
    const redirectUrl = `${window.location.origin}/auth/confirm-account`;
    const { dataError, functionError } = await registerAccount(
      email?.toLowerCase(),
      password,
      redirectUrl,
      firstName,
      lastName,
      phone,
    );

    const error = dataError && dataError.error;
    if (error && error.length) {
      const errorObj = {};
      error.forEach((item) => {
        errorObj[item.field] = item.message;
      });
      setFieldErrorMessages(errorObj);
      return error;
    } else if (functionError) {
      console.error(functionError);
      const snackbarID = `${Date.now().toString()}error`;
      addSnackbar({
        id: snackbarID,
        onClose: () => closeSnackbar(snackbarID),
        message: 'Something went wrong. Please try again.',
        variant: SnackbarVariant.ERROR,
      });
      return functionError;
    }
  };
  const responseGoogle = async (response) => {
    if (!response?.access_token && !response?.code) {
      return;
    }
    try {
      const res = await oauthSignIn({
        accessToken: response.code || response.access_token,
        backend: AuthMethodEnum.GOOGLE,
        onLoginFailed,
        onLoginSucceeded,
      });
      setResponseData(res);
    } catch (err) {
      console.error('Internal server error');
    }
  };
  const responseApple = async (response) => {
    if (!response?.authorization?.code) {
      if (response?.error) {
      }
      return;
    }

    try {
      const firstName = response?.user?.name?.firstName;
      const lastName = response?.user?.name?.lastName;
      const res = await oauthSignIn({
        accessToken: response?.authorization?.code,
        backend: AuthMethodEnum.APPLE,
        firstName,
        lastName,
        onLoginFailed,
        onLoginSucceeded,
      });
      setResponseData(res);
    } catch (err) {
      console.error('Internal server error', err);
    }
  };
  const responseFacebook = async (response) => {
    try {
      const res = await oauthSignIn({
        accessToken: response.accessToken,
        backend: AuthMethodEnum.FACEBOOK,
        onLoginFailed,
        onLoginSucceeded,
      });
      setResponseData(res);
    } catch (err) {
      console.error('Internal server error');
    }
  };

  return (
    <>
      <SignUpDialog
        registerAccount={handleSignUp}
        onLoginClick={openLoginDialog}
        isOpen={open_dialog === PageDialog.signup}
        onClose={closeDialog}
        fieldErrorMessages={fieldErrorMessages}
        responseGoogle={responseGoogle}
        responseFacebook={responseFacebook}
        responseApple={responseFacebook}
      />
    </>
  );
}
