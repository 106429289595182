import { useEffect, useState } from 'react';
import { useAuth, useProductsUserDataLazyQuery } from 'teddly-sdk';
import { useCartContext } from '@context/CartContext';
import {
  PageDialog,
  usePageLayoutContext,
} from '../../../context/PageLayoutContext';
import { sortProductsByVendors as sortVariantsByVendors } from '@utils/vendors';
import {
  Cart,
  EmptyState,
  Button,
  Card,
  StaticAlert,
  ButtonVariantEnum,
  StaticAlertVariantEnum,
  ImagePositionEnum,
} from '@teddly/teddly-ui-components';
import { useRoutesContext } from '@context/RoutesContext';
import { NewVariant } from '@interfaces';
import styles from './CartPanel.module.scss';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useNetworkState } from '@hooks/useNetworkState';
import { ids } from '../../../../cypress/pages/cart/CartPage.cy';
import { getVariants } from '@app/products/api';
import { ICheckoutModelLine } from 'teddly-sdk/lib/helpers';
import EmptyCartButtonIcon from 'public/assets/remove_shopping_cart.svg';
import { emptyImageSrc } from '@utils';
import { useChannelContext } from '@context/ChannelContext';
import CartConflictDialog from '@components/Pages/Checkout/components/CartConflictDialog';

export default function CartPanel({
  viewOnly = false,
}: {
  viewOnly?: boolean;
}) {
  const {
    variants,
    discountPrice,
    giftCards,
    shippingPrice,
    subTotalPrice,
    taxPrice,
    tip,
    isCalculatePrice,
    total,
    defaultCheckoutCount,
    fastDeliveryCheckoutCount,
    changeCheckoutType,
    isCheckoutTypeUpdating,
    addCartVariant,
    removeCartVariant,
    onEmptyCartClick,
  } = useCartContext();

  const { dispatch, isCartOpen, open_dialog, closeDialog } =
    usePageLayoutContext();

  const isCartEmpty: boolean =
    variants?.filter((variant) => variant?.line?.isInclude)?.length === 0;

  const { navigateTo, pages } = useRoutesContext();
  const [vendorVariants, setVendorVariants] = useState({});
  const { openProductDialog } = usePageLayoutContext();
  const { checkout } = useCheckoutFlowContext();
  const { user } = useAuth();
  const [fetchProductsUserData, { loading }] = useProductsUserDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  const isCartConflict = checkout?.isCartChannelConflict;
  const getAndSetProductsUserData = async (variantList: NewVariant[]) => {
    const copyVariantList = [...variantList];
    if (user && copyVariantList?.length > 0) {
      const objectsIds = variantList?.map((v) => v.product_id);
      const response = await fetchProductsUserData({
        variables: { ids: objectsIds },
      });
      const productsUserDataList = response.data.productsUserData;
      copyVariantList.forEach((variant) => {
        const node = productsUserDataList?.find(
          (productUserData) =>
            variant?.product_id === productUserData?.productId,
        );
        if (node) {
          variant.lastOrdered = node.lastOrdered;
        }
      });
    }

    return copyVariantList;
  };
  const getAndSetVariants = async () => {
    const variantsWithLastPurchase = await getAndSetProductsUserData(
      variants?.filter((variant) => variant?.line?.isInclude),
    );
    setVendorVariants(sortVariantsByVendors(variantsWithLastPurchase));
  };

  useEffect(() => {
    getAndSetVariants();
  }, [variants]);

  const handleCheckout = async () => {
    await navigateTo({ route: pages.CHECKOUT });
    onClose();
  };
  const onClose = () => {
    dispatch({ type: 'TOGGLE_CART', value: false });
  };

  const getSumOrZero = (sum: number) => (isCartEmpty ? 0 : sum);
  const getListSumValue = () => {
    const list = [
      {
        title: 'Subtotal',
        value: `$${getSumOrZero(subTotalPrice)}`,
        dataTestId: 'subtotal-value',
      },
      {
        title: 'Tax',
        value: `$${getSumOrZero(taxPrice)}`,
        dataTestId: 'tax-value',
      },
      {
        title: 'Delivery',
        value: `$${getSumOrZero(shippingPrice)}`,
        dataTestId: 'delivery-value',
      },
    ];
    if (tip) {
      list.push({ title: 'Tip', value: `$${tip}`, dataTestId: 'tip-value' });
    }
    if (giftCards?.giftCardsUsageAmount?.amount) {
      list.push({
        title: 'Gift card',
        value: `$${giftCards.giftCardsUsageAmount.amount}`,
        dataTestId: 'giftcard-value',
      });
    }
    if (discountPrice) {
      list.push({
        title: 'Discount',
        value: `$${discountPrice}`,
        dataTestId: 'discount-value',
      });
    }
    return list;
  };

  const { selectedChannel } = useChannelContext();

  const [isEditOrderMessageOpen, setIsEditOrderMessageOpen] =
    useState<boolean>(true);

  const minusIndex = 0;
  const plusIndex = 0;
  const editOrderCardTitle = (
    <div>
      <div
        style={{
          fontSize: '25px',
          width: '85%',
          fontWeight: 700,
          lineHeight: '30px',
        }}>
        Here you can edit your order while it's pending
      </div>
      <div style={{ fontSize: '14px', lineHeight: '20px', paddingTop: '16px' }}>
        need to add a new item to order? or maybe you need to change the
        quantity or size of an item? No problem! Edit your order and just hit
        the checkout button.
      </div>
    </div>
  );
  const editOrderMessageCard = (
    <Card
      title={editOrderCardTitle}
      imageSrc={
        'https://teddly-dev-space.nyc3.cdn.digitaloceanspaces.com/images/home-ad.png'
      }
      stretch
      imagePosition={ImagePositionEnum.TOP}
      className={styles.editOrderMessageCard}
      // onClose={()=>setIsEditOrderMessageOpen(false)}
    />
  );

  const [isEmptyCartLoading, setIsEmptyCartLoading] = useState(false);

  const handleOnEmptyCartClick = async () => {
    setIsEmptyCartLoading(true);
    await onEmptyCartClick();
    setIsEmptyCartLoading(false);
  };

  const emptyCartButton = (
    <Button
      variant={ButtonVariantEnum.OUTLINE}
      title={'Empty Cart'}
      onClick={handleOnEmptyCartClick}
      loading={isEmptyCartLoading}
      icon={<EmptyCartButtonIcon />}
    />
  );

  const cartConflictAlert = (
    <StaticAlert
      variant={StaticAlertVariantEnum.WARNING}
      title="Items in this cart cannot be delivered to this address — please empty the cart to continue"
      showIcon={false}
      children={[emptyCartButton]}
    />
  );

  return (
    <>
      <CartConflictDialog
        isOpen={open_dialog === PageDialog.cartConflict}
        onClose={closeDialog}
      />
      <Cart
        disabled={isCartConflict}
        isSupportFastDelivery={selectedChannel?.isSupportFastDelivery}
        isCard={isEditOrderMessageOpen}
        card={isCartConflict ? cartConflictAlert : editOrderMessageCard}
        loading={isCheckoutTypeUpdating}
        availableFastDeliveryItemsCount={fastDeliveryCheckoutCount}
        availableDefaultCheckoutItemsCount={defaultCheckoutCount}
        checkoutOrder={checkout?.editingOrder}
        itemDetailsMinusNumberDataTestId={
          ids.itemDetailsMinusNumber + minusIndex
        }
        itemDetailsPlusNumberDataTestId={ids.itemDetails}
        checkoutButtonDataTestId={ids.checkoutButton}
        categoriesItemsDataTestId={ids.categoriesItems}
        orderSummaryButtonDataTestId={ids.orderSummaryButton}
        allItemsTabDataTestId={ids.allItemsTab}
        fastDeliveryTabDataTestId={ids.fastDeliveryTab}
        availableOrdersForEdit={checkout?.availableOrdersForEdit}
        checkoutType={checkout?.type}
        onCheckoutTypeChange={changeCheckoutType}
        cartEmptyMessage={
          <EmptyState
            imageSrc={emptyImageSrc}
            btn={
              <Button
                data-testid={ids.addItemsButton}
                variant="outline"
                size={'small'}
                title="add items"
                onClick={() => {
                  onClose();
                  navigateTo({ route: '/grocery' });
                }}
              />
            }
            title={'Your cart is empty'}
            subTitle={"Sad, isn't it?"}></EmptyState>
        }
        onClickCheckout={() =>
          user
            ? handleCheckout()
            : dispatch({ type: 'OPEN_DIALOG', dialog: PageDialog.login })
        }
        onCloseCart={onClose}
        onItemClick={(variant: NewVariant) =>
          openProductDialog({ product_id: variant.product_id })
        }
        isCartOpen={isCartOpen}
        variantsByVendor={vendorVariants} // send id
        isCalculate={isCalculatePrice}
        listSum={getListSumValue()}
        cartSumTitle={'Total'}
        cartSumValue={'$' + getSumOrZero(total)}
        addCartVariant={(value, variant) => {
          // variant.line = { ...variant.line, quantity: value };
          addCartVariant(variant, value);
        }}
        removeCartVariant={(value, variant) => {
          // variant.line = { ...variant?.line, quantity: value };
          addCartVariant(variant, value);
        }}
        onDelete={(value, variant) => {
          if (!isCheckoutTypeUpdating) {
            variant.line = { ...variant?.line, quantity: 0 };
          }
          removeCartVariant(variant);
        }}></Cart>
    </>
  );
}
